<template>
  <div class="player">
    <video class="player__video" controls crossorigin playsinline ref="video" :poster="poster">
      <source :src="url" type="application/x-mpegURL" />
    </video>
  </div>
</template>

<script>
export default {
  name: "PlyrWrapper",
  props: {
    url: String,
    poster: String,
  },
  mounted() {
    require(["plyr", "hls.js"], (Plyr, Hls) => {
      let isTablet = window.innerWidth <= 1024;
      let isMobile = window.innerWidth <= 768;
      let controls = [
        "play-large",
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "captions",
        "settings",
        "pip",
        "airplay",
        "fullscreen",
      ];
      if (isTablet) {
        controls = ["play-large", "play", "progress", "captions", "volume", "settings", "fullscreen"];
      }
      if (isMobile) {
        controls = ["play-large", "play", "progress", "captions", "settings", "fullscreen"];
      }
      const video = this.$refs.video;

      function updateQuality(newQuality) {
        window.hls.levels.forEach((level, levelIndex) => {
          if (level.height === newQuality) {
            console.log("Found quality match with " + newQuality);
            window.hls.currentLevel = levelIndex;
          }
        });
      }

      if (!Hls.isSupported()) {
        video.src = this.url;
      } else {
        const hls = new Hls({
          xhrSetup: (xhr) => {
            xhr.setRequestHeader("Authorization", `Bearer ` + this.$store.state.apollo_token);
          },
          fetchSetup: (context, initParams) => {
            initParams.headers["Authorization"] = `Bearer ` + this.$store.state.apollo_token;
            return new Request(context.url, initParams);
          },
        });
        window.hls = hls;
        hls.loadSource(this.url);

        // From the m3u8 playlist, hls parses the manifest and returns
        // all available video qualities. This is important, in this approach,
        // we will have one source on the Plyr player.
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // Transform available levels into an array of integers (height values).
          const availableQualities = hls.levels.map((l) => l.height);

          new Plyr(video, {
            controls: controls,
            quality: {
              default: availableQualities[0],
              options: availableQualities,
              // this ensures Plyr to use Hls to update quality level
              // Ref: https://github.com/sampotts/plyr/blob/master/src/js/html5.js#L77
              forced: true,
              onChange: (e) => updateQuality(e),
            },
          });
        });
        hls.attachMedia(video);
      }
    });
  },
};
</script>

<style lang="stylus">
.player {
  --plyr-color-main: var(--main_color)

  .plyr {
    width 100%
  }
  &__video{
    width 100%
  }
}

@import "~plyr/dist/plyr.css"
</style>
